/* src/styles/ScrollImageSection.css */




  .scroll-image-section {
    position: relative;
    height: 20vh;
    overflow: visible;
    /* background: linear-gradient(to bottom, rgba(229, 202, 249, 0.7), rgba(168, 99, 221, 0.48), rgba(246, 237, 255)); */
  }
  .scroll-effect-image {
    max-width: 80%;
    max-height: 80vh;
    position: absolute;
    top: 75%; /* Position the top edge of the image 75% down the parent */
    left: 40%; /* Start from the center */
    will-change: transform;
    transform: translateX(-50%) translateY(50%); /* Center the image */
    z-index: 100; /* Ensure it is layered above the wave SVG */
  }
  @media screen and (max-width:768px) {
    .scroll-effect-image {
      display: none;
    }
    .scroll-effect-image.second{
      display: flex;
      justify-self: center;
      max-width: 80%;
    max-height: 80vh;
    position: absolute;
    top: 75%; /* Position the top edge of the image 75% down the parent */
    left: 80%; /* Start from the center */
    will-change: transform;
    transform: translateX(-50%) translateY(50%); /* Center the image */
    z-index: 100; /* Ensure it is layered above the wave SVG */
    }
  }
  
  .scroll-effect-image.second {
    /* Shift the second image (phone) to the right */
    position: absolute;
    left: 80%;
    transform: translateX(0%) translateY(70%); /* Adjust this to set the right position */
  }
